import './location-info-modal.tpl.php';
import './style.scss';

jQuery($ => {
  $(document).on('click', '.launch-location-info-modal', function(e) {
    e.preventDefault();
    $('.location-info__modal').fadeIn();
    $('body').addClass('modal-open');
  });

  $(document).on('click', '.location-info__close-button', function() {
    $('.location-info__modal').hide();
    $('.location-info__map-overlay').show();
    $('body').removeClass('modal-open');
  });

  $(document).on('click', '.location-info__map-overlay', function() {
    $(this).hide();
  });
});

import './hero-image.tpl.php';
import './style.scss';

require('slick-carousel');

jQuery($ => {
  const heroComponent = document.querySelector('.hero-image');
  if (!heroComponent) {
    return;
  }

  heroVideoInit();
  heroVimeoInit();

  const heroImageSlick = $('.hero-image__init-slick-carousel.hero-image__carousel');
  if (heroImageSlick.length > 0) {
    $(heroImageSlick).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    });
  }

  /**
   * @param heroVideo
   */
  function heroVideoModifier(heroVideo) {
    let deviceType = '';
    let videoSource = '';
    let updateVideo = false;

    if ($(window).width() >= 768 && deviceType !== 'desktop') {
      videoSource = heroVideo.data('desktop-video');
      deviceType = 'desktop';
      updateVideo = true;
    } else if ($(window).width() < 768 && deviceType !== 'mobile') {
      videoSource = heroVideo.data('mobile-video');
      deviceType = 'mobile';
      updateVideo = true;
    }

    if (updateVideo) {
      heroVideo.html(`<video id="video" autoplay loop muted playsinline><source src="${videoSource}"></video>`);
    }
  }

  function heroVideoInit() {
    const heroVideo = $('.hero-image__video');
    if (heroVideo.length < 1) {
      return;
    }

    heroVideoModifier(heroVideo);

    let resizeTimer;
    $(window).on('resize', e => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        heroVideoModifier(heroVideo);
      }, 250);
    });
  }

  function heroVimeoInit() {
    const heroVimeo = $('.hero-image__vimeo');
    if (heroVimeo.length < 1) {
      return;
    }

    heroVimeoModifier(heroVimeo);

    let resizeTimer;
    $(window).on('resize', e => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        heroVimeoModifier(heroVimeo);
      }, 250);
    });
  }

  /**
   * @param heroVimeo
   */
  function heroVimeoModifier(heroVimeo) {
    let deviceType = '';
    let updateVideo = false;
    let vimeoSourceId = '';

    if ($(window).width() >= 768 && deviceType !== 'desktop') {
      vimeoSourceId = heroVimeo.data('desktop-video');
      deviceType = 'desktop';
      updateVideo = true;
    } else if ($(window).width() < 768 && deviceType !== 'mobile') {
      vimeoSourceId = heroVimeo.data('mobile-video');
      deviceType = 'mobile';
      updateVideo = true;
    }

    if (updateVideo) {
      var srcParsed = 'https://iframe.videodelivery.net/' + vimeoSourceId + '?muted=true&loop=true&autoplay=true&poster=https://videodelivery.net/' + vimeoSourceId + '/thumbnails/thumbnail.jpg&controls=false&primaryColor=white';
      heroVimeo.attr('src', srcParsed);
      heroVimeo.css('display', 'block');
    }
  }
});

import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';
import './style.scss';
import './search-results-card.tpl.php';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery($ => {
  const searchResultsCards = document.querySelector('.search-results-cards');

  if (!searchResultsCards) {
    return;
  }

  window.objectFitPolyfill();
  addEllipsis($, '.search-results-cards h6.ellipsis-line');
  addEllipsis($, '.search-results-cards .description.ellipsis-line');
});

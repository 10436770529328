import './form-elements.tpl.php';
import './style.scss';
import 'script-loader!bootstrap-sass/assets/javascripts/bootstrap/tooltip';

jQuery($ => {
  if ($('.chosen-select').length > 0) {
    $('.chosen-select').chosen({ disable_search_threshold: 10 });
  }

  $('.webform-client-form select').addClass('chosen-select');
  $(document).on('change', 'select.day, select.month, select.year', function() {
    jQuery('form select').trigger('chosen:updated');
  });
  $(document).ready(function() {
    $('form [data-toggle="tooltip"]').tooltip({ placement: 'top' });
  });
});

import './card-event.tpl.php';
import './style.scss';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery(document).ready(function($) {
  addEllipsis($, '.card-event-container .exhibition-subtitle');

  $('.card-event-container .desktop-toggle.draw-toggle').click(function(e) {
    $(this)
      .closest('card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });

  $('.card-event-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .parent()
      .toggleClass('open-wrapper');
    e.preventDefault();
  });

  $('.card-event-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .next('.card-event-container .card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });
});

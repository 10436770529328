import './menu-block.tpl.php';
import './style.scss';

jQuery($ => {
  $(initialize);
  window.menuBlock = initialize;
});

function initialize($) {
  const triggers = $('.mobile-accordion-nested i, .mobile-accordion-full-click h5');

  // Open top level on click.
  $('.navigation > .mobile-accordion-top-level').click(function(event) {
    const elem = $(this).children('i');
    open(elem);
    event.preventDefault();
  });
  $(triggers).each((index, element) => {
    $(element).click(function(event) {
      const elem = $(this);
      open(elem);
      event.preventDefault();
    });
  });

  // Make menu link active if the child is active.
  $('.navigation > .mobile-accordion-content .hover-menu-links .accordion-secondary').each(function() {
    let $mobileAccordionContent = $(this).find('> .mobile-accordion-content');
    if ($mobileAccordionContent.find('ul.hover-menu-links li a').hasClass('active')) {
      // The parent is on the same level as the child structurally and before the child, so we use prev().
      $mobileAccordionContent.prev().addClass('mobile-accordion-open');
    }
  });
}

function open(elem) {
  if (elem.parent().hasClass('mobile-accordion-open')) {
    elem.parent().removeClass('mobile-accordion-open');
  } else {
    elem.parent().addClass('mobile-accordion-open');
  }
  elem
    .parent()
    .children('i')
    .toggleClass('open');
}

import './collection-object-info-area.tpl.php';
import './style.scss';
import './arrow7.png';

jQuery($ => {
  $(document).off(
    'click',
    '.collection-object-info-area__accordion > li .collection-object-info-area__accordion-tab a'
  );
  $(document).on(
    'click',
    '.collection-object-info-area__accordion > li .collection-object-info-area__accordion-tab a',
    function(e) {
      $('.collection-object-info-area__accordion > li').removeClass('selected');
      $(this)
        .closest('li')
        .addClass('selected');

      var dropDown = $(this)
        .closest('li')
        .find('.collection-object-info-area__accordion-inside');
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }

      dropDown
        .stop(false, true)
        .slideToggle()
        .css('display', 'flex');

      e.preventDefault();
      return false;
    }
  );
});

import './school-session-location-tabs.tpl.php';
import './style.scss';

jQuery(initialize);
window.schoolSessionTabs = initialize;

function initialize($) {
  const el = $('.school-session-location-tabs');

  el.find('.tab_content').hide();
  el.find('.tab_content:first').show();

  let currentActiveTab = '';

  el.find('ul.tabs li').click(function(e) {
    const activeTab = el.find(this).attr('rel');
    if (currentActiveTab == activeTab) {
      return false;
    }
    currentActiveTab = activeTab;
    el.find('.tab_content').hide();
    el.find('#' + activeTab).fadeIn();

    el.find('ul.tabs li').removeClass('active');
    el.find(this).addClass('active');

    el.find('.tab_drawer_heading').removeClass('d_active');
    el.find(".tab_drawer_heading[rel^='" + activeTab + "']").addClass('d_active');

    e.preventDefault();
  });

  el.find('.tab_drawer_heading').click(function() {
    var d_activeTab = el.find(this).attr('rel');
    el.find('#' + d_activeTab).fadeToggle();

    el.find(this).toggleClass('d_active');

    el.find('ul.tabs li').removeClass('active');
    el.find("ul.tabs li[rel^='" + d_activeTab + "']").addClass('active');
  });

  el.find('ul.tabs li')
    .last()
    .addClass('tab_last');

  el.find('.tab_drawer_heading')
    .last()
    .addClass('tab_drawer_heading_last');

  el.find(document).on('click', '.school-session-location-tabs__map-overlay', function() {
    el.find(this).hide();
  });
}

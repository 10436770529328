import './on-this-page-accordion.tpl.php';
import './style.scss';

jQuery($ => {
  const myhash = window.location.hash.replace(/^##?\/?#?/, '');
  let isDesktop = $(window).width() > 767;

  var extraOffset = 0;
  var isMicrosite = jQuery('.page .is-microsite');
  if (isMicrosite) {
    extraOffset = 80;
  }

  smoothStrollToLink(myhash, extraOffset);
  function smoothStrollToLink(myhash, extraOffset = 0) {
    try {
      if (myhash && myhash.length && $(`#${myhash}`).length) {
        const target = document.getElementById(myhash);

        if (target !== null) {
          setTimeout(() => {
            const offset = target.offsetTop - 80 - extraOffset;
            window.scroll({
              behavior: 'smooth',
              top: offset,
            });

            if (!isDesktop) {
              let targeth2 = target.getElementsByTagName('h2')[0];
              if (typeof targeth2.click == 'function') {
                targeth2.click();
              }
            }
          }, 0);
        }
      }
    } catch (err) {}
  }

  // Scroll smoothly to a link on the same page.
  $('.on-this-page-accordion a[href*=\\#]').click(function(e) {
    const myhash = window.location.hash.replace(/^##?\/?#?/, '');

    let currentLink = window.location.pathname + '#' + myhash;
    let windowOrigin = window.location.origin;

    let linkhref = this.href;
    let linkhrefPieces = linkhref.replace(windowOrigin, '').split('#');
    let currentLinkPieces = currentLink.split('#');

    if (linkhrefPieces[0] == currentLinkPieces[0]) {
      var extraOffset = 0;

      var isMicrosite = jQuery('.page .is-microsite');
      if (isMicrosite) {
        extraOffset = 60;
      }
      smoothStrollToLink(linkhrefPieces[1], extraOffset);
      e.preventDefault();
    }
  });

  $('.on-this-page-accordion__mobile-accordion-content > .grey-background.mobile-white')
    .closest('.on-this-page-accordion__mobile-accordion')
    .addClass('white-background');

  $('.on-this-page-accordion__mobile-accordion-content > .grey-background')
    .closest('.on-this-page-accordion__mobile-accordion')
    .addClass('grey-background');

  $('.on-this-page-accordion__accordion-title').click(function(e) {
    if (isDesktop) {
      return false;
    }

    $(this)
      .find('.icon-RCT-down')
      .toggleClass('arrow-active');

    const content = $(this)
      .closest('.on-this-page-accordion__mobile-accordion')
      .find('.on-this-page-accordion__mobile-accordion-content');

    const dropDown = content;
    content.not(dropDown).slideUp();

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    dropDown.stop(false, true).slideToggle();

    e.preventDefault();
    return false;
  });

  $('.on-this-page-accordion__accordion-close').click(function(j) {
    if (isDesktop) {
      return false;
    }

    const mobileAccordEl = $(this).closest('.on-this-page-accordion__mobile-accordion');
    mobileAccordEl.find('.icon-RCT-down').toggleClass('arrow-active');
    const content = mobileAccordEl.find('.on-this-page-accordion__mobile-accordion-content');

    const dropDown = content;
    content.not(dropDown).slideUp();

    const titleEl = mobileAccordEl.find('.on-this-page-accordion__accordion-title');

    if (titleEl.hasClass('active')) {
      titleEl.removeClass('active');
    } else {
      titleEl.addClass('active');
    }

    dropDown.stop(false, true).slideToggle();
    j.preventDefault();

    $('html, body').animate(
      {
        scrollTop: titleEl.offset().top,
      },
      500
    );
  });
});

import './locations-map.tpl.php';
import './style.scss';
import mapClusterSmall from './map-cluster-small.svg';
import mapClusterMedium from './map-cluster-medium.svg';
import mapClusterLarge from './map-cluster-large.svg';
import mapPinCrown from './map-pin-crown.svg';
import 'intersection-observer';

jQuery(window).load(() => {
  mapInit();
});

window.mapInit = mapInit;

function mapInit() {
  if (jQuery('.locations-map').length < 1) {
    return;
  }

  const options = {
    rootMargin: '100px',
    threshold: 0,
  };

  const map = document.getElementById('map');

  let observer = new IntersectionObserver(function(entries, self) {
    // Intersecting with Edge workaround https://calendar.perfplanet.com/2017/progressive-image-loading-using-intersection-observer-and-sqip/#comment-102838
    var isIntersecting =
      typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
    if (isIntersecting) {
      mapAddMap();
      self.unobserve(map);
    }
  }, options);

  observer.observe(map);
}

function mapAddMap() {
  const $mapDiv = jQuery('#map');
  let $center = $mapDiv.data('gmap-center');
  let needsAutoCenter = false;
  const locations = $mapDiv.data('gmap-locations');
  const bounds = new google.maps.LatLngBounds();

  if ($center === '') {
    needsAutoCenter = true;
    $center = new google.maps.LatLng(54.140851, -2.338054);
  }

  if ($mapDiv.length > 0) {
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 16,
      maxZoom: 18,
      center: $center,
      disableDefaultUI: true,
      zoomControl: true,
    });

    google.maps.event.addListenerOnce(map, 'idle', () => {
      if (locations.length < 1) {
        return;
      }

      let defaultInfoWindowContent = 'loading...';
      const infowindow = new google.maps.InfoWindow({
        content: defaultInfoWindowContent,
        maxWidth: 250,
      });

      const infowindowClose = function() {
        infowindow.close();
      };

      const setDefaultInfoWindowContents = function(content) {
        defaultInfoWindowContent = content;
        infowindow.setContent(content);
      };

      const markers = locations.map((location, i) => {
        const m = new google.maps.Marker({
          position: {
            lat: location.lat,
            lng: location.lng,
          },
          icon: mapPinCrown,
        });
        bounds.extend(location);

        google.maps.event.addListener(
          m,
          'click',
          (marker => () => {
            infowindow.open(map, marker);
            const domNode = document.getElementById(`location_info_${i}`).innerHTML;
            setDefaultInfoWindowContents(domNode);

            jQuery('.locations-map__close-icon').each((index, element) => {
              jQuery(element).click(() => {
                infowindowClose();
              });
            });
          })(m)
        );

        return m;
      });

      const fontFamily = "'Gill Sans Nova Medium', 'Lucida Grande','Lucida Sans Unicode','Verdana,Arial,sans-serif'";
      const textColor = '#8A2A2B';

      const clusterStyles = [
        {
          url: mapClusterSmall,
          height: 57,
          width: 57,
          'font-family': fontFamily,
          textColor,
          textSize: 14,
        },
        {
          url: mapClusterMedium,
          height: 81,
          width: 81,
          'font-family': fontFamily,
          textColor,
          textSize: 18,
        },
        {
          url: mapClusterLarge,
          height: 107,
          width: 107,
          'font-family': fontFamily,
          textColor,
          textSize: 22,
        },
      ];

      const clusterOptions = {
        maxZoom: 14,
        gridSize: 100,
        styles: clusterStyles,
        batchSizeIE: 100,
      };

      const markerClusterer = new MarkerClusterer(map, markers, clusterOptions, {});

      let markersInView = [];

      function markersInViewportCount() {
        if (!markerClusterer) {
          return [];
        }
        const mapMarkers = markerClusterer.getMarkers();
        markersInView = [];
        for (let i = 0; i < mapMarkers.length; i++) {
          if (typeof map.getBounds === 'function') {
            if (map.getBounds().contains(markers[i].getPosition())) {
              markersInView.push(markers[i]);
            }
          }
        }
        return markersInView;
      }

      function haveMarkersInViewport() {
        if (markersInViewportCount().length < locations.length) {
          zoomMapToFindMarkers();
        }
      }

      function zoomMapToFindMarkers() {
        if (markersInViewportCount().length === 0) {
        }
        const zoomLevel = parseInt(map.getZoom() - 1);

        if (zoomLevel < 0) {
          return;
        }
        map.setZoom(zoomLevel);
        setTimeout(() => {
          haveMarkersInViewport();
        }, 250);
      }

      setTimeout(() => {
        if (needsAutoCenter) {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        } else {
          haveMarkersInViewport();
        }
      }, 100);
    });
  }
}

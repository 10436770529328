import React, { Component } from 'react'
import classNames from 'classnames'
import request from 'superagent'
import './style.scss'
import loader from './loader.svg'
import loaderWhite from './loader-white.svg'

const CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION = 2;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      debounce: null,
      searchTerm: '',
      results: [],
      highlighted: -1,
      isMicrosite: this.props.isMicrositeText ? this.props.isMicrositeText : false,
      themeColor: this.props.themeColorText ? this.props.themeColorText : null,
      placeholder: this.props.placeholderText ? this.props.placeholderText : 'Search the Royal Collection Trust site',
    };
  }

  componentDidMount() {
    const self = this;
    if (window.innerWidth < 768) {
      this.setState({
        placeholder: 'Search the site',
      });
    }
    jQuery('.search-toggle').click(function(e) {
      if (jQuery('.header').hasClass('open')) {
        self.setState({
          searchTerm: '',
          highlighted: -1,
        });
      }
      e.preventDefault();
    });
  }

  _handleSearchTermChange(e) {
    this.setState({
      searchTerm: e.target.value,
      loading: this.props.autoComplete ? true : false,
    });
    if (this.props.autoComplete) {
      clearTimeout(this.state.debounce);
      this.state.debounce = setTimeout(
        function() {
          this._request(this.state.searchTerm, this.props.microsite);
        }.bind(this),
        300
      );
    }
  }

  _handleSearchTermChangeAutoComplete(e) {
    let searchAutoCompleteWrapperInputText = jQuery(
      '.search-auto-complete-wrapper .search-auto-complete__autocomplete__input-text'
    );
    if (e.target.value) {
      searchAutoCompleteWrapperInputText.addClass('open');
    } else {
      searchAutoCompleteWrapperInputText.removeClass('open');
    }

    let searchAutoCompleteWrapperLoader = jQuery(
      '.search-auto-complete-wrapper .search-auto-complete__autocomplete__input-text .search-auto-complete__autocomplete__loader'
    );
    if (searchAutoCompleteWrapperLoader != 'undefined') {
      if (e.target.value.length <= CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION) {
        searchAutoCompleteWrapperLoader.text('Continue typing to see suggestions...');
      }
    }

    this.setState({
      searchTerm: e.target.value,
      loading: this.props.autoComplete ? true : false,
    });
    if (this.props.autoComplete) {
      clearTimeout(this.state.debounce);
      this.state.debounce = setTimeout(
        function() {
          this._request(this.state.searchTerm, this.props.microsite);
        }.bind(this),
        300
      );
    }

    searchAutoCompleteWrapperInputText.find('.search-for-keyword__text').text(e.target.value);
  }

  _handleKeyPress(e) {
    let index = this.state.highlighted;
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
    }
    if (e.key === 'ArrowDown' && index + 1 < this.state.results.length) {
      this.setState({ highlighted: index + 1 });
      if (index == -1) {
        index = 0;
      }
      jQuery('.results .inner').css({
        transform:
          'translateY(' +
          (jQuery('.results .inner').position().top - jQuery('#search_item' + index).position().top) +
          'px)',
      });
    }
    if (e.key === 'ArrowUp' && index != 0) {
      this.setState({ highlighted: index - 1 });
      if (index === 0 || index < 2) {
        jQuery('.results .inner').css({ transform: 'translateY(0px)' });
      } else {
        jQuery('.results .inner').css({
          transform:
            'translateY(' +
            (jQuery('.results .inner').position().top - jQuery('#search_item' + (index - 2)).position().top) +
            'px)',
        });
      }
    }
    if (e.key === 'Enter') {
      if (this.state.highlighted == -1) {
        if (this.state.searchTerm.match(/\w.*\b(\/)\w.*?(?:(?!\/).)*/g)) {
          this.state.searchTerm = this.state.searchTerm.replace(/\//g, '--');
        }
        this._go(this._getSearchPath() + '/' + encodeURIComponent(this.state.searchTerm), null);
      } else {
        this._go(this.state.results[this.state.highlighted].url, this.state.results[this.state.highlighted].id);
      }
    }
  }

  _getSearchPath() {
    return this.props.searchPath ? this.props.searchPath : '/search/site';
  }

  _request(q, microsite) {
    const domain = this._getApiDomain();
    // Escape forward slashes with "--".
    let query = q.replace(/\//g, '--');
    let requestUrl = '//' + domain + '/collection/search-api/auto-complete/site/' + query;
    if (!microsite) {
      requestUrl += '?microsite=false';
    }
    if (this.props.isMicrositeText && this.props.gid) {
      let requestUrl = '//' + domain + '/search-api/microsite/auto-complete/' + query;
      this._requestMicrositeResult(requestUrl);
    } else {
      this._requestResult(requestUrl);
    }
  }

  _requestResult(requestUrl) {
    request
      .get(requestUrl)
      .set('Accept', 'application/json')
      .set('Content-Type', 'text/plain')
      .end((err, res) => {
        if (err) {
          return;
        }
        let results = [];
        JSON.parse(res.text).map(function(item, id) {
          if (item.type.name) {
            results.push({ name: item.type.name, count: item.type.count, id: item.type.id, label: true });
            let childs = item.results.map(function(result, index) {
              results.push(result);
            });
          }
        });
        this.setState({
          results: results,
          loading: false,
        });
      });
  }

  _requestMicrositeResult(requestUrl) {
    request
      .get(requestUrl)
      .set('Accept', 'application/json')
      .set('Content-Type', 'text/plain')
      .end((err, res) => {
        if (err) {
          return;
        }
        let results = [];
        JSON.parse(res.text).map(function(item, id) {
          jQuery.each(item.results, function(key, results_item) {
            let childs = results_item.map(function(result, index) {
              results.push(result);
            });
          });
        });
        this.setState({
          results: results,
          loading: false,
        });
      });
  }

  _go_to_search_with_facet(item) {
    if (item) {
      if (this.props.isMicrositeText) {
        const searchFacetsStorage = {
          ['microsite']: {
            keyword: '',
            filters: [item],
            current_page: this.props.searchPath,
            non_applicable_search_page_visit_counter: 0,
          },
        };
        localStorage.setItem('react_search_microsite_facets', JSON.stringify(searchFacetsStorage));
        window.location = this.props.searchPath;
      }
    }
  }

  _go_to_search_with_term(searchTerm) {
    if (searchTerm) {
      if (this.props.isMicrositeText) {
        const searchTermUrlPath = (searchTerm.match(/\w.*\b(\/)\w.*?(?:(?!\/).)*/g)) ? searchTerm.replace(/\//g, '--') : searchTerm;
        let url = [this.props.searchPath, encodeURIComponent(searchTermUrlPath) + '/page/1'].join('/');

        if (this.props.microsite) {
          const searchFacetsStorage = {
            ['microsite']: {
              keyword: searchTerm,
              filters: [],
              current_page: url,
              non_applicable_search_page_visit_counter: 0,
            },
          };
          localStorage.setItem('react_search_microsite_facets', JSON.stringify(searchFacetsStorage));
        }
        window.location = url;
      }
    }
  }

  /**
   * Get the domain of the API.
   *
   * @returns {string}
   *   e.g. api.royalcollection.org.uk
   */
  _getApiDomain() {
    return location.host;
  }

  _go(url, id) {
    if (url) {
      if (this.props.microsite) {
        const searchFacetsStorage = {
          ['microsite']: {
            keyword: this.state.searchTerm,
            filters: [],
            current_page: url,
            non_applicable_search_page_visit_counter: 0,
          },
        };
        localStorage.setItem('react_search_microsite_facets', JSON.stringify(searchFacetsStorage));
      }
      window.location = url;
    }
    if (!url && id) {
      window.location = this._getSearchPath() + '/' + this.state.searchTerm + '?f[0]=im_field_search_type:' + id;
    }
  }

  _render() {
    const classes = classNames({
      'react-search-component': true,
      searching: this.state.searchTerm && !this.state.loading,
      loading: this.state.searchTerm && this.state.loading,
    });

    const results = this.state.results.map(function(item, id) {
      const highlighted = id === this.state.highlighted ? 'result highlighted' : 'result';
      return (
        <a
          onClick={this._go.bind(this, item.url, item.id)}
          id={'search_item' + id}
          key={'result' + id}
          className={highlighted}
        >
          <span className="icon-RCT-arrow"></span>
          {item.label && (
            <h5 className="paragraph-font-bold">
              {item.name} <small>{item.count}</small>
            </h5>
          )}
          {!item.label && <p className="subtitle" dangerouslySetInnerHTML={{ __html: item.name }} />}
        </a>
      );
    }, this);

    return (
      <span className={classes}>
        <div className="search-bar" style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}>
          <div className="loader" style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}>
            <img src={loader} alt="" />
          </div>
          <div
            className="search-button center-vertical search-toggle"
            style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}
          >
            <i className="icon-RCT-search" />
          </div>
          <input
            placeholder={this.state.placeholder}
            ref="search_input"
            className="center-vertical"
            value={this.state.searchTerm}
            onKeyDown={this._handleKeyPress.bind(this)}
            onChange={this._handleSearchTermChange.bind(this)}
          />
          <a href="#" className="close-search search-toggle">
            <i className="icon-RCT-close" />
          </a>
        </div>
        {this.props.autoComplete && (
          <div className="results">
            <div className="inner">
              {!this.state.searchTerm && (
                <div className="pad">
                  <h1>Search results</h1>
                  <p>Start typing</p>
                </div>
              )}
              {this.state.searchTerm.length > 3 && this.state.searchTerm && !this.state.loading && results.length == 0 && (
                <div className="no-results">
                  <p>No results found for "{this.state.searchTerm}"</p>
                </div>
              )}
              {this.state.searchTerm && !this.state.loading && results}
            </div>
          </div>
        )}
      </span>
    );
  }

  _autocomplete_render() {
    const classes = classNames({
      'react-search-component': true,
      searching:
        this.state.searchTerm &&
        !this.state.loading &&
        this.state.searchTerm.length > CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION,
      loading:
        this.state.searchTerm &&
        this.state.loading &&
        this.state.searchTerm.length > CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION,
    });

    let searchAutoCompleteWrapperLoader = jQuery(
      '.search-auto-complete-wrapper .search-auto-complete__autocomplete__input-text .search-auto-complete__autocomplete__loader'
    );
    if (searchAutoCompleteWrapperLoader != 'undefined') {
      if (this.state.loading && this.state.searchTerm.length > CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION) {
        searchAutoCompleteWrapperLoader.css({ padding: '6px 30px' });
        searchAutoCompleteWrapperLoader.text('Loading');
      } else if (this.state.searchTerm.length <= CHARACTER_COUNT_TO_TRIGGER_AUTOCOMPLETE_SUGGESTION) {
        searchAutoCompleteWrapperLoader.css({ padding: '6px 30px' });
        searchAutoCompleteWrapperLoader.text('Continue typing to see suggestions...');
      } else {
        searchAutoCompleteWrapperLoader.css({ padding: '0' });
        searchAutoCompleteWrapperLoader.text('');
      }
    }

    const results = this.state.results.map(function(item, id) {
      const highlighted =
        id === this.state.highlighted ? 'result search-auto-complete highlighted' : 'result search-auto-complete';
      return (
        <li
          className={highlighted}
          onClick={this._go_to_search_with_facet.bind(this, item)}
          id={'search_item' + id}
          key={'result' + id}
        >
          <span className="search-auto-complete__autocomplete__name">
            <Mark highlight={this.state.searchTerm}>{item.name}</Mark>
          </span>
          <span className="search-auto-complete__autocomplete__count">
            {' '}
            (<span className="search-auto-complete__autocomplete__subject">{item.display_type}, </span>
            {item.count})
          </span>
        </li>
      );
    }, this);

    return (
      <span className={classes}>
        <div className="search-bar" style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}>
          <div className="loader" style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}>
            <img className="loader-white" src={loaderWhite} alt="" />
            <img className="loader-default" src={loader} alt="" />
          </div>
          <div
            className="search-button center-vertical search-toggle"
            style={this.state.themeColor ? { backgroundColor: this.state.themeColor } : {}}
          >
            <i className="icon-RCT-search" />
          </div>
          <input
            placeholder={this.state.placeholder}
            ref="search_input"
            className="center-vertical"
            value={this.state.searchTerm}
            onKeyDown={this._handleKeyPress.bind(this)}
            onChange={this._handleSearchTermChangeAutoComplete.bind(this)}
          />
          <a href="#" className="close-search search-toggle">
            <i className="icon-RCT-close" />
          </a>
        </div>
        {this.props.autoComplete && (
          <div className="search-auto-complete-wrapper">
            <div className="search-auto-complete__autocomplete__input-text">
              <div onClick={this._go_to_search_with_term.bind(this, this.state.searchTerm)}>
                Search <span className="search-for-keyword__text"></span> as a keyword...
              </div>
              <span className="search-auto-complete__autocomplete__title">Filter suggestions</span>
              <span className="search-auto-complete__autocomplete__loader"></span>
            </div>
            <div className="results is-autocomplete">
              <div className="inner">
                <ul className="search-auto-complete__autocomplete">
                  {!this.state.searchTerm && (
                    <div className="pad">
                      <h1>Search results</h1>
                      <p>Start typing</p>
                    </div>
                  )}
                  {this.state.searchTerm.length > 3 &&
                    this.state.searchTerm &&
                    !this.state.loading &&
                    results.length == 0 && (
                      <div className="no-results">
                        <p>No results found for "{this.state.searchTerm}"</p>
                      </div>
                    )}
                  {this.state.searchTerm && !this.state.loading && results}
                </ul>
              </div>
            </div>
          </div>
        )}
      </span>
    );
  }

  render() {
    if (this.state.isMicrosite) {
      return this._autocomplete_render();
    } else {
      return this._render();
    }
  }
}

// Adapted from https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs.
function Mark({ highlight, children }) {
  // Split text on higlight term, include term itself into parts, ignore case
  const parts = children.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part
      )}
    </span>
  );
}

export default Search;

import './on-this-page.tpl.php';
import './style.scss';

require('smoothscroll-polyfill').polyfill();

jQuery($ => {
  const anchorLinks = document.querySelectorAll(
    '.on-this-page__container__link, .on-this-page-accordion__back-link-link'
  );
  let offset = 0;

  for (let i = 0; i < anchorLinks.length; i++) {
    anchorLinks[i].addEventListener(
      'click',
      function(e) {
        if (window.scroll) {
          if (!this.getAttribute('href')) {
            return;
          }
          const target = document.getElementById(this.getAttribute('href').substring(1));

          e.preventDefault();
          if (target !== null) {
            offset = target.offsetTop - 80;
          }
          window.scroll({
            behavior: 'smooth',
            top: offset,
          });
        }
      }.bind(anchorLinks[i])
    );
  }
});

import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';
import './homepage-splash-image.tpl.php';
import './style.scss';
import Cookie from 'js-cookie';
import './rct-logo-white.svg';
import './rct-logo-white.png';

jQuery($ => {
  window.objectFitPolyfill();

  const splashImage = $('.homepage-splash-image');
  if (splashImage.length < 1) {
    return;
  }

  // The user is already down the page so remove the splash image and don't show it.
  if ($(window).scrollTop() > 0 /* || Cookie.get('homepageViewSplash::' + Drupal.settings.getQ) === 'false' */) {
    $(splashImage).css('display', 'none');
    $(splashImage).css('background-image', '');
    return;
  }

  const imageHeight = splashImage.height();
  const splashImageOffset = splashImage.offset().top - 80;
  const splashImageOffsetMobile = splashImage.offset().top;
  const splashImageHeight = splashImage.outerHeight();
  let hasScrolled = false;

  $('html').css('padding-top', '0px');
  $('.header').css('position', 'relative');
  $('.results').css('display', 'none');

  $('.header.is-microsite').addClass('splash-image-visible');
  $('.header-bg.is-microsite').addClass('splash-image-visible');

  function splashImageDestroy() {
    if ($(window).width() < 991) {
      $('.header').css('position', '');
    } else {
      $('.header').css('position', 'fixed');
    }
    $('html').css('padding-top', '');
    $(splashImage).css('display', 'none');
    $(splashImage).css('background-image', '');
    $('.results').css('display', 'block');

    $('.header.is-microsite').removeClass('splash-image-visible');
    $('.header-bg.is-microsite').removeClass('splash-image-visible');

    window.scrollTo(0, 0);
    Cookie.set('homepageViewSplash::' + Drupal.settings.getQ, 'false', { expires: 14 });
  }

  function splashImageTopDesktop() {
    if ($(window).scrollTop() > splashImageOffset + splashImageHeight) {
      splashImageDestroy();
    }
  }

  function splashImageTopMobile() {
    if ($(window).scrollTop() > splashImageOffsetMobile + splashImageHeight) {
      splashImageDestroy();
    }
  }

  $(window).scroll(() => {
    if (!hasScrolled) {
      hasScrolled = true;
      $('html,body').animate(
        {
          scrollTop: splashImageOffset + imageHeight,
        },
        1000,
        'swing',
        () => {
          splashImageDestroy();
        }
      );
    }
    if ($(splashImage).css('display') !== 'none') {
      $(splashImage).css('opacity', 1 - $(window).scrollTop() / imageHeight);
      $('.header-bg, .heaader').css('opacity', $(window).scrollTop() / imageHeight);

      if ($(window).width() < 991) {
        splashImageTopMobile();
      } else {
        splashImageTopDesktop();
      }
    }
  });

  $('.homepage-splash-image__section-button').click(() => {
    $(window).scroll();
  });
});

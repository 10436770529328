import './group-search-header.tpl.php';
import './style.scss';

jQuery(document).ready(function($) {
  $('.group-search-header .filters button').click(function(e) {
    e.preventDefault();
    $(this)
      .find('.tick')
      .toggleClass('active');

    window.location.href = $(this).data('query');
  });
});

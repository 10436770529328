import './stylesheets/_bootstrap.scss';
import './style.scss';
import './select-caret.png';

jQuery(document).ready(function() {
  if (jQuery('body').hasClass('page-collection-search') || jQuery('body').hasClass('page-collection-near-you')) {
    if (jQuery('.chosen-select').length > 0) {
      jQuery('.chosen-select').chosen({});
    }

    jQuery('#active-filter-btn').click(function() {
      jQuery('#active-filter-tab')
        .get(0)
        .click();
    });

    jQuery(document).on('click', '.share-page, .mobile-jump-to-page', function(e) {
      jQuery(this).toggleClass('open');
      e.preventDefault();
    });

    jQuery(document).on('click', '#navbar .dropdown.open a', function(e) {
      if (!jQuery(this).hasClass('visible-xs')) {
        var el = jQuery(this);
        window.location = el.attr('href');
      }
      e.preventDefault();
    });

    jQuery('.prevent-default').click(function(e) {
      e.preventDefault();
    });

    jQuery(
      '.page-collection-search .move-to-pagination-ul-on-page-load, .page-collection-near-you .move-to-pagination-ul-on-page-load, .page-collection-themes-search .move-to-pagination-ul-on-page-load'
    ).each(function() {
      var el = jQuery(this);
      el.appendTo('ul.pagination');
    });

    jQuery('#jump-to-page')
      .mouseover(function() {
        var el = jQuery(this);
        el.find('input').focus();
      })
      .mouseout(function() {
        var el = jQuery(this);
        setTimeout(function() {}, 500);
      });

    function jumpToPage() {
      var url = document.URL;
      var currentPage = parseInt(jQuery('.pager-current').text()) - 1;
      var page = parseInt(jQuery('#jump-to-page input').val());
      if (isNaN(page)) {
        return;
      }
      if (url.indexOf('page=') >= 0) {
        console.log(currentPage);
        window.location = url.replace('page=' + currentPage, 'page=' + (parseInt(page) - 1));
      } else {
        var operator = '?';
        if (url.indexOf('?') >= 0) {
          var operator = '&';
        }
        window.location = url + operator + 'page=' + (parseInt(page) - 1);
      }
    }

    jQuery('.page-search-site #jump-to-page .btn').click(function() {
      jumpToPage();
    });

    jQuery('.page-search-site #jump-to-page input').keyup(function(e) {
      if (e.keyCode == 13) {
        jumpToPage();
      }
    });

    jQuery('[data-toggle="popover"]').popover();

    jQuery('body').popover({
      selector: '[data-popover]',
      trigger: 'click hover',
      placement: 'auto',
      delay: { show: 0, hide: 500 },
    });
  }

  var sideslider = jQuery('[data-toggle=collapse-side]');
  if (sideslider.length > 0) {
    var sel = sideslider.attr('data-target');
    var sel2 = sideslider.attr('data-target-2');
    sideslider.click(function(event) {
      jQuery(sel).toggleClass('in');
      jQuery(sel)
        .find('.panel-collapse')
        .removeClass('in');
      var target = jQuery(this).attr('data-refine-target');
      jQuery('#sb-' + target).addClass('in');
    });
  }

  // Whats on stuff - @todo move to whastsOn component.
  if (jQuery('body').hasClass('page-whatson') || jQuery('body').hasClass('page-schools')) {
    var dateRange = jQuery('#sb-when li[data-provide]');
    if (dateRange.length > 0) {
      jQuery('.input-daterange')
        .datepicker({
          format: 'dd/mm/yyyy',
          startDate: 'now',
          todayHighlight: true,
          autoclose: true,
        })
        .on('show', function(ev) {
          const datePicker = jQuery('.datepicker');
          let offsetTop = document.body.className.match('not-logged-in') ? 120 : 150;
          let offsetLeft = 25;
          if (window.outerWidth < 991) {
            offsetTop = offsetTop - 35;
          }
          datePicker.css({
            top: parseInt(datePicker.css('top')) + offsetTop,
            left: parseInt(datePicker.css('left')) + offsetLeft,
          });
        })
        .on('changeDate', function(e) {
          dateRange.attr(
            'data-date-range',
            '[' +
              moment(jQuery('.input-daterange input:first').datepicker('getDate')).format('YYYY-MM-DDT[00:00:00Z]') +
              ' TO ' +
              moment(jQuery('.input-daterange input:last').datepicker('getDate')).format('YYYY-MM-DDT[23:59:59Z]') +
              ']'
          );
        });
    }

    if (jQuery('#path1').length) {
      function SvgPath(id, clickselector) {
        var self = this;

        this.aniOpen = true;

        // Perform the all animation
        var clickFunction = function() {
          var el = jQuery(this);

          if (self.aniOpen === true) {
            self.aniOpen = false;
            el.addClass('active');
          } else {
            self.aniOpen = true;
            el.removeClass('active');
          }
          return false;
        };

        jQuery(clickselector).on('click ani-click', clickFunction);

        if (jQuery(clickselector).hasClass('active')) {
          this.aniOpen = false;
        }
      }

      var path1 = new SvgPath('path1', '.draw1');
      var where = new SvgPath('where1', '.draw2');
      if (jQuery('#who1').length) {
        var who = new SvgPath('who1', '.draw3');
        var what = new SvgPath('what1', '.draw4');
      }
      if (jQuery('#subjects1').length) {
        var subjects = new SvgPath('subjects1', '.draw5');
        var age = new SvgPath('age1', '.draw6');
        var curriculum = new SvgPath('curriculum1', '.draw7');
      }
    }
  }
});

import './group-theme-meta-object-carousel.tpl.php';
import './style.scss';
import screenfull from 'screenfull';
import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';

jQuery($ => {
  window.objectFitPolyfill();

  const slickRefresh = () =>
    $('.slick-slider')
      .filter((k, e) => e.slick)
      .each((k, e) => e.slick.refresh());

  const el = $('.group-theme-meta-object-carousel');

  /**
   * Set the slider
   *
   * @param slide
   * @param index
   */
  var sliderSet = function(slide, index) {
    if (index + 1 < slide.slideCount) {
      jQuery('.next-carousel-placeholder').show();
    } else {
      jQuery('.next-carousel-placeholder').hide();
      jQuery('.next').each(function() {
        jQuery(this)
          .children('.hidden-xs')
          .html(jQuery(this).attr('data-title') + ' <i class="icon-RCT-arrow"></i>');
      });
    }

    if (index == 0) {
      jQuery('.prev-carousel-placeholder').hide();
      jQuery('.prev .hidden-xs').html('<i class="icon-RCT-arrow-left"></i> ' + jQuery('.prev').attr('data-title'));
    } else {
      jQuery('.prev-carousel-placeholder').show();
    }
  };

  const $carousel = el.find(
    '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel'
  );
  if ($carousel.length) {
    $carousel
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        asNavFor: '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__nav',
      })
      .on('afterChange', function(event, slick, currentSlide, nextSlide) {
        // then let's do this before changing slides
        sliderSet(slick, currentSlide);
      });
    $carousel.trigger('afterChange', [$carousel.get(0).slick, 0]);
  }

  const navSlick = el.find(
    '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__nav'
  );
  navSlick.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel',
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    dotsClass: 'custom_paging',
    customPaging: function(slider, i) {
      return i + 1 + ' of ' + slider.slideCount;
    },
  });

  const slickSliderCount = navSlick.slick('getSlick').slideCount;
  if (slickSliderCount <= 5) {
    navSlick.addClass('slick-no-slide');
  } else {
    navSlick.removeClass('slick-no-slide');
  }

  el.on('open-full-screen.collection-object-image', () => {
    const w = el.find('.full-screen-wrapper');

    function fsHandler() {
      w.toggleClass('in-full-view', screenfull.isFullscreen);
      if (!screenfull.isFullscreen) {
        screenfull.off('change', fsHandler);
      }
      slickRefresh();
    }

    screenfull.on('change', fsHandler);
    screenfull.request(w[0]);
  });

  el.on('close-full-screen.collection-object-image', () => {
    screenfull.exit();
  });

  el.on('open-full-screen.collection-object-image', () => {
    el.find('.full-screen-wrapper').addClass('container full-container');
  });
});

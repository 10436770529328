import './share-tool.tpl.php';
import './style.scss';

// Dirty hack to make this available to angular.
jQuery(fullHeroImage);
window.heroImage = fullHeroImage;

function fullHeroImage($) {
  // Social functions.
  var openSharePopup = function(shareUrl, width, height) {
    var w = width;
    var h = height;
    var sharePopupParams = {
      top: screen.height / 2 - h / 2,
      left: screen.width / 2 - w / 2,
    };
    window.open(
      shareUrl,
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        sharePopupParams.top +
        ', left=' +
        sharePopupParams.left
    );
  };

  if (window.location.href.indexOf('?close_window') !== -1) {
    window.close();
  }

  $(document).on('click', '.share-tool-container .share-tool .trigger-addthis-tweet', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Twitter', 'tweet', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.hero-image .addthis_button_twitter').trigger('click');
    e.preventDefault();
    return false;
  });

  $(document).on('click', '.share-tool-container .share-tool .trigger-addthis-email', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Email', 'share', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.hero-image .addthis_button_email').trigger('click');
    e.preventDefault();
  });

  $(document).on('click', '.share-tool-container .share-tool .trigger-addthis-pinterest', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Pinterest', 'share', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.hero-image .addthis_button_pinterest_pinit').trigger('click');
    e.preventDefault();
  });

  $(document).on('click', '.share-tool-container .share-tool .trigger-addthis-whatsapp', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'WhatsApp', 'share', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.hero-image .addthis_button_whatsapp').trigger('click');
    e.preventDefault();
  });
}

import './collection-accordion.tpl.php';
import './style.scss';
import './arrow7.png';

jQuery($ => {
  $(document).off('click', '.collection-accordion__accordion > li .collection-accordion__accordion-tab a');
  $(document).on('click', '.collection-accordion__accordion > li .collection-accordion__accordion-tab a', function(e) {
    const dropDown = $(this)
      .closest('li')
      .find('.collection-accordion__accordion-inside');
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    dropDown
      .stop(false, true)
      .slideToggle()
      .css('display', 'block');

    e.preventDefault();
    return false;
  });
});

import './card-school-event.tpl.php';
import './style.scss';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery(document).ready(function($) {
  addEllipsis($, '.card-school-event-container p');

  $('.card-school-event-container .desktop-toggle.draw-toggle').click(function(e) {
    $(this)
      .closest('card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });

  $('.card-school-event-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .parent()
      .toggleClass('open-wrapper');
    e.preventDefault();
  });

  $('.card-school-event-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .next('.card-school-event-container .card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });
});

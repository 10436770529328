import './search-pagination.tpl.php';
import './style.scss';

jQuery(document).ready($ => {
  $.QueryString = (function(paramsArray) {
    const params = {};

    for (let i = 0; i < paramsArray.length; ++i) {
      const param = paramsArray[i].split('=', 2);

      if (param.length !== 2) continue;

      params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, ' '));
    }

    return params;
  })(window.location.search.substr(1).split('&'));

  const $jumpToPageForm = $('.search-pagination__jump-to-page-container');
  const $jumpToPageNumber = $('#jump-to-page-number');
  const jumpToPageSubmit = e => {
    e.preventDefault();
    $.QueryString.page = parseInt($jumpToPageNumber.val()) - 1;
    window.location.href = `${window.location.pathname}?${$.param($.QueryString)}`;
  };

  $jumpToPageForm.on('submit', 'form', jumpToPageSubmit);
  $jumpToPageForm.on('touchend', 'form button', jumpToPageSubmit);
});

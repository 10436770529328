import './search-results-filters.tpl.php';
import './style.scss';

jQuery(document).ready(function($) {
  $('.filter button').click(function(e) {
    e.preventDefault();
    $(this)
      .find('.tick')
      .toggleClass('active');

    window.location.href = $(this).data('query');
  });

  $('.mobileTitle').click(function(j) {
    var dropDown = $('.accordion-inside');

    $('.accordion-inside')
      .not(dropDown)
      .slideUp();

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    dropDown.stop(false, true).slideToggle();

    j.preventDefault();
  });
});

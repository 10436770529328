import './footer.tpl.php';
import './style.scss';
import './social-icons/facebook.svg';
import './social-icons/instagram.svg';
import './social-icons/vimeo.svg';
import './social-icons/twitter.svg';
import './social-icons/contact.svg';
import './social-icons/facebook-white.svg';
import './social-icons/instagram-white.svg';
import './social-icons/vimeo-red.svg';
import './social-icons/twitter-white.svg';
import './social-icons/contact-white.svg';
import './social-icons/yt_icon_rgb.svg';
import './social-icons/yt_icon_mono_light.svg';
import './social-icons/yt_icon_mono_dark.svg';
import './blank-image.png';

jQuery($ => {
  function setHeightOfPartnershipLogos() {
    var rctLogoWrapper = $('.col-xs-6.rct-logo-wrapper').height();
    $('.col-xs-6.in-partnership-with-wrapper').height(rctLogoWrapper);
    var inPartnerShipWithWrapper = parseInt(rctLogoWrapper) / 2 - 2;
    $('.col-xs-6.in-partnership-with-wrapper span').css({ top: inPartnerShipWithWrapper + '%' });

    if ($(window).width() <= 445) {
      var heightAdjustment = 27;
    }

    if ($(window).width() >= 568 && $(window).width() < 767) {
      heightAdjustment = 39;
    }

    if ($(window).width() >= 767 && $(window).width() < 992) {
      heightAdjustment = 31;
    }

    if ($(window).width() >= 768 && $(window).width() < 992) {
      var parntershipLogoOneHeight = $('.col-xs-6.partnership-logo-one').height();
      var parntershipLogoWrapperImageHeight = parseInt(parntershipLogoOneHeight) / 2 - 23.5;
      $('.col-sm-6.partnership-logo-wrapper.first').css({ top: parntershipLogoWrapperImageHeight + 'px' });
    } else {
      $('.col-sm-6.partnership-logo-wrapper.first').css({ top: 'initial' });
    }
  }

  setHeightOfPartnershipLogos();
  $(window).on('resize', e => {
    setHeightOfPartnershipLogos();
  });
});

import './find-related-themes.tpl.php';
import './style.scss';

// Dirty hack to make this available to angular.
jQuery(initialize);
window.findRelatedChevron = initialize;

// Used as a count to determine hiding an icon based on the number of lines for the relevant element.
const MAX_NUMBER_OF_LINES_TO_HIDE_ICON = 3;

function initialize($) {
  /**
   * Get number of lines within an element.
   *
   * @param target
   *
   * @returns {number}
   */
  function getNumberOfLines(target) {
    let style = window.getComputedStyle(target, null);
    let height = parseInt(style.getPropertyValue('height'));
    let fontSize = parseInt(style.getPropertyValue('font-size'));
    let lineHeight = parseInt(style.getPropertyValue('line-height'));
    let boxSizing = style.getPropertyValue('box-sizing');

    if (isNaN(lineHeight)) lineHeight = fontSize * 1.2;

    if (boxSizing == 'border-box') {
      let paddingTop = parseInt(style.getPropertyValue('padding-top'));
      let paddingBottom = parseInt(style.getPropertyValue('padding-bottom'));
      let borderTop = parseInt(style.getPropertyValue('border-top-width'));
      let borderBottom = parseInt(style.getPropertyValue('border-bottom-width'));
      height = height - paddingTop - paddingBottom - borderTop - borderBottom;
    }

    return Math.ceil(height / lineHeight);
  }

  $('.collapseable').click(function() {
    $(this)
      .parent()
      .parent()
      .toggleClass('open');
  });

  let innerContentElementHeight = parseInt(
    jQuery('.blocker .find-related-themes--unique .inner-content').css('min-height')
  );
  let heightElement = jQuery('.blocker .find-related-themes--unique .inner-content p').get(0);
  if (typeof heightElement != 'undefined') {
    if (
      getNumberOfLines(heightElement) <= MAX_NUMBER_OF_LINES_TO_HIDE_ICON &&
      heightElement.scrollHeight < innerContentElementHeight
    ) {
      $('.find-related-themes .icon-RCT-down').hide();
      $('.find-related-themes .heading').removeClass('collapseable');
    }
  }
}

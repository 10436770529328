import './style.scss'
import './header.tpl.php'
import React from 'react'
import { render } from 'react-dom'
import Search from './reactSearchComponent'
import './logo.svg'
import './down-arrow.svg'
import './down-arrow-white.svg'
import './mobile-menu-icon.svg'
import './mobile-menu-icon-white.svg'
import './trolley.svg'
import { addEllipsis } from '../../../jsPlugins/ellipsis'

// Add this to the window so it can be used within the angular app.
window.addEllipsis = addEllipsis;

jQuery($ => {
  if ($('.header').length < 1) {
    return;
  }

  setTimeout(() => {
    const iframe = document.getElementById('ticketing-sync');
    let iframeWin;
    // some browser (don't remember which one) throw exception when you try to access
    // contentWindow for the first time, it work when you do that second time
    try {
      iframeWin = iframe.contentWindow;
    }
    catch (e) {
      iframeWin = iframe.contentWindow;
    }
    window.addEventListener('message', function (e) {
      console.log('origin', e.origin);
      if (e.origin !== "https://rct-ticketing-app-stage.web.app" &&
        e.origin !== "https://rct-ticketing-app.web.app" &&
        e.origin !== "https://tickets.rct.uk" &&
        e.origin !== "https://stage-tickets.rct.uk"
      ) {
        return;
      }
      console.log('check the data', e.data.data);
      try {
        const data = JSON.parse(e.data.data);
        document.querySelector('.basket-quantity__oval').innerHTML = data.basketQuantity;
        document.querySelector('.basket-quantity__container').style.display = data.basketQuantity > 0 ? 'block' : 'none';
        document.querySelector('.menu.list-inline').classList.add(data.basketQuantity > 0 ? 'menu__basket--spacer' : '');
        document.querySelectorAll('.tickets-menu__basket span').forEach((item) => item.innerHTML = `(${data.basketQuantity})`);
        document.querySelectorAll('.tickets-menu__logged-out').forEach((item) => item.style.display = !data.isLoggedIn ? 'block' : 'none');
        document.querySelectorAll('.tickets-menu__logged-in').forEach((item) => item.style.display = data.isLoggedIn ? 'block' : 'none');
      } catch (e) {
        console.error('error parsing the data');
      }
    }, false);
    iframeWin.postMessage({ action: 'getData' }, "*");
  }, 1000);

  const el = document.getElementById('react-search-component');
  const autoComplete = typeof el.dataset.autoComplete !== 'undefined' ? el.dataset.autoComplete === 'true' : true;
  const searchPath = typeof el.dataset.searchPath !== 'undefined' ? el.dataset.searchPath : null;
  const placeholderText = typeof el.dataset.placeholderText !== 'undefined' ? el.dataset.placeholderText : null;
  const gid = typeof el.dataset.gid !== 'undefined' ? el.dataset.gid : null;
  const isMicrositeText =
    typeof el.dataset.isMicrositeText !== 'undefined' ? el.dataset.isMicrositeText === 'true' : false;
  const themeColorText = typeof el.dataset.themeColorText !== 'undefined' ? el.dataset.themeColorText : null;
  render(
    <Search
      autoComplete={autoComplete}
      searchPath={searchPath}
      placeholderText={placeholderText}
      gid={gid}
      isMicrositeText={isMicrositeText}
      themeColorText={themeColorText}
    />,
    el
  );

  window.addEventListener(
    'scroll',
    function(event) {
      if (this.pageYOffset === 0) {
        $('.header, .header-bg').removeClass('header-sm');
      } else {
        $('.header, .header-bg').addClass('header-sm');
      }
    },
    false
  );

  $('.header .menu > li > a').focus(function() {
    const el = $(this);
    $('.header .focused').removeClass('focused');
    el.parent().addClass('focused');
  });

  $('.header .menu > li > a').hover(
    function() {
      const el = $(this);
      $('.header .focused').removeClass('focused');
      el.parent().addClass('focused');
    },
    function() {
      $('.header .focused').removeClass('focused');
    }
  );

  $('.header .menu > li > a.mobile-open').click(function(e) {
    const el = $(this).parent();
    if (!el.hasClass('open')) {
      el.addClass('open');
    } else {
      el.removeClass('open');
    }
    e.preventDefault();
  });

  $('.mobile-toggle').click(function(e) {
    $('.header').toggleClass('open');
    e.preventDefault();
  });

  $('.search-toggle').click(function(e) {
    var $header = $('.header');
    $header.toggleClass('open');
    if ($header.hasClass('open')) {
      $header.find('.react-search-component input').focus();
    }

    if (!jQuery('.header.is-microsite').hasClass('open') && jQuery('.search-auto-complete__autocomplete__input-text')) {
      jQuery('.search-auto-complete__autocomplete__input-text').removeClass('open');
    }
    e.preventDefault();
  });

  addEllipsis($, '.ellipsis');
  addEllipsis($, '.ellipsis-2-line');
  addEllipsis($, '.ellipsis-3-line');
  addEllipsis($, '.ellipsis-4-line');
  addEllipsis($, '.ellipsis-5-line');
  addEllipsis($, '.ellipsis-h5-2-line');
  addEllipsis($, '.ellipsis-h6-3-line');
  addEllipsis($, '.white-card .description');
});

import './paragraph-ticket-listings.tpl.php'

jQuery(($) => {
  if ($('.paragraph-ticket-listings-card').length < 1) {
    return;
  }

  // Get the 'but tickets' button.
  const buyButton = document.querySelector('.residence-intro__button a');
  buyButton.addEventListener('click', function (e) {
    if (window.scroll) {
      if (!this.getAttribute('href')) {
        return;
      }
      const targetId = this.getAttribute('href').substring(this.getAttribute('href').indexOf('#') + 1);
      const target = document.getElementById(targetId);

      e.preventDefault();
      e.stopPropagation();
      if (target !== null) {
        const offset = target.offsetTop - 80;
        window.scroll({
          behavior: 'smooth',
          top: offset,
        });
        // Open the accordion if mobile.
        document.querySelector(`#${targetId} .on-this-page-accordion__accordion-title`).click();
      }
    }
  });

  // Switcher CTA click event.
  $('.switcher').click(function (e) {
    e.preventDefault();

    // Toggle visibility between different ticket type containers.
    const parentWrapper = $(this).closest('.paragraph-ticket-listings-card');
    if (parentWrapper.hasClass('individuals')) {
      parentWrapper.next('.paragraph-ticket-listings-card').removeClass('hidden');
    } else {
      parentWrapper.prev('.paragraph-ticket-listings-card').removeClass('hidden');
    }

    parentWrapper.addClass('hidden');
  });
});

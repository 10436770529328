import './rct-breadcrumb.tpl.php';
import './style.scss';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery($ => {
  addEllipsis($, '.breadcrumb ul');
  addEllipsis($, '.breadcrumb li');

  $(document).on('click', '.breadcrumb-toggle', function(e) {
    const el = $(this).parent();
    if (!el.hasClass('open')) {
      el.addClass('open');
    } else {
      el.removeClass('open');
    }
    addEllipsis($, '.breadcrumb ul');
    addEllipsis($, '.breadcrumb li');
    e.preventDefault();
  });
});

import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';
import './card-collection-object.tpl.php';
import './style.scss';
import './result-placeholder.png';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery($ => {
  addEllipsis($, '.card-collection-object-container .short-description');
  window.objectFitPolyfill();

  $('.card-collection-object-container .desktop-toggle.draw-toggle').click(function(e) {
    $(this)
      .closest('card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });

  $('.card-collection-object-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .parent()
      .toggleClass('open-wrapper');
    e.preventDefault();
  });

  $('.card-collection-object-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .next('.card-collection-object-container .card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });
});

import './collection-object-header.tpl.php';
import './style.scss';

jQuery($ => {
  if (window.location.hash.indexOf('referer') != -1) {
    var referer_nid = window.location.hash.split('/')[2];
    var referer_tab_nid = window.location.hash
      .split('/')
      .slice(-2)
      .pop();
    getParameterByName('prodId');
    $.ajax({
      type: 'POST',
      url: '/referer/get',
      dataType: 'json',
      data: 'referer_nid=' + referer_nid,
      success: function(node) {
        $('.object-search-link button').text('Back to ' + node.title);
        $('.object-search-link .btn').data('url', node.url + '#/tab/' + referer_tab_nid);
        $('.object-search-link').fadeIn();
      },
    });
  } else {
    $('.object-search-link').fadeIn();
  }

  function getParameterByName(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  $(document).off('click', '.node-type-collection2 .object-search-link .btn');
  $(document).on('click', '.node-type-collection2 .object-search-link .btn', function(e) {
    if ($(this).data('url')) {
      window.location = $(this).data('url');
    } else {
      window.location = '/collection/search';
    }
    e.preventDefault();
  });
});

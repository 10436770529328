import './card-collection-object-near-you.tpl.php';
import './style.scss';
import { addEllipsis } from '../../../jsPlugins/ellipsis';

jQuery($ => {
  window.objectFitPolyfill();

  $('.card-collection-object-near-you-container .desktop-toggle.draw-toggle').click(function(e) {
    $(this)
      .closest('card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });

  $('.card-collection-object-near-you-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .parent()
      .toggleClass('open-wrapper');
    e.preventDefault();
  });

  $('.card-collection-object-near-you-container .mobile-toggle.draw-toggle').click(function(e) {
    $(this)
      .next('.card-collection-object-near-you-container .card-collection-object')
      .toggleClass('open');
    e.preventDefault();
  });

  $(document).on('click', '.location-pop-up__link', function(e) {
    $(this)
      .find('.location-pop-up__text')
      .toggleClass('open');
    addEllipsis($, '.card-collection-object-container .location-pop-up__text span.ellipsis');
    e.preventDefault();
  });
});

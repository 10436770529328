import './card-expander.tpl.php';
import './style.scss';

// Dirty hack to make this available to angular.
jQuery(initialize);
window.initExpanders = initialize;

function initialize($) {
  $('.card-expander')
    .not('.expander-processed')
    .each((i, el) => {
      $('.card-expander__sm-toggle a', el).click(e => {
        $(el).toggleClass('card-expander--open');
        $('.card-expander__sm-toggle', el).toggleClass('card-expander__toggle--open');
        $('.card-expander__md-toggle', el).toggleClass('card-expander__toggle--open');
        e.preventDefault();
      });

      $('.card-expander__md-toggle a', el).click(e => {
        $(el).toggleClass('card-expander--open');
        $('.card-expander__sm-toggle', el).toggleClass('card-expander__toggle--open');
        $('.card-expander__md-toggle', el).toggleClass('card-expander__toggle--open');
        e.preventDefault();
      });

      $(el).addClass('expander-processed');
    });
}

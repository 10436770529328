import './popup-modal.tpl.php';
import './style.scss';
import Cookie from 'js-cookie';

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

jQuery($ => {
  const component = '.popup-modal';
  const items = $(`${component}__item`);

  if (items.length < 0) {
    return;
  }

  const myhash = window.location.hash.replace(/^##?\/?/, '');
  try {
    if (myhash && myhash.length && $(`${component}__${myhash}`).length) {
      const target = $(`${component}__${myhash}`);

      if (target !== null) {
        setTimeout(() => {
          const openButton = target.find(`${component}__button`);
          $(openButton).click();
        }, 100);
      }
    }
  } catch (err) {}

  const path = window.location.pathname;
  let cookies = Cookie.getJSON('popup_modals');
  let showPopup = true;

  if (typeof cookies != 'object') {
    cookies = {};
    showPopup = true;
  } else {
    if (cookies.hasOwnProperty(path)) {
      let date = cookies[path];
      let oldDate = new Date(date);
      showPopup = !sameDay(oldDate, new Date());
    }
  }
  cookies[path] = Date.now();
  Cookie.set('popup_modals', cookies);

  items.each((i, el) => {
    const item = $(el);

    const openButton = item.find(`${component}__button`);
    const closeButton = item.find(`${component}__button-close`);
    const modalWindow = item.find(`${component}__modal-window`);

    if (showPopup) {
      setTimeout(function() {
        modalWindow.show();
        $('body').addClass('modal-open');
      }, 3000);
    }

    closeButton.click(() => {
      modalWindow.hide();
      $('body').removeClass('modal-open');
    });
  });
});

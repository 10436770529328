import './style.scss';
import './search-results-filters-order-by.tpl.php';

jQuery($ => {
  const searchResultsOrderBy = document.querySelector('.search-results-order-by');

  if (!searchResultsOrderBy) {
    return;
  }

  $('.search-results-order-by__accordion-button').click(() => {
    $('.search-results-order-by__accordion-content').toggleClass('open');
    $('.icon-RCT-down').toggleClass('rotated');
  });
});

import './collection-object-image.tpl.php';
import './style.scss';
import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';

jQuery($ => {
  window.objectFitPolyfill();

  $('body')
    .on('click', '.collection-object-image .zoom-out, .collection-object-image .zoom-in', ({ target }) => {
      const el = $(target).closest('.collection-object-image');

      const markup = $(`<div class="collection-object-image-zoom-wrapper in">
        <div class="object-btns object-btns__zoom">
          <button class="zoom-out"><a class="icon-zoom-out pointer" title="Zoom Out"></a></button>
          <button class="zoom-in"><a class="icon-zoom-in pointer" title="Zoom In"></a></button>
        </div>

        <div class="object-btns object-btns__close" id="close-zoom">
          <button class="close-zoom"><a class="icon icon-cross2 pointer" title="Close Zoom"></a></button>
        </div>

        <div class="zoom"></div>
      </div>`);

      $('body').append(markup);

      el.trigger('close-full-screen.collection-object-image');

      markup.find('.zoom').smoothZoom(
        Object.assign({
          image_url: el.find('.carousel-img').data('largeimg'),
          width: $(window).width(),
          height: $(window).height(),
          zoom_BUTTONS_SHOW: 'NO',
          pan_BUTTONS_SHOW: 'NO',
          pan_LIMIT_BOUNDARY: 'NO',
          zoom_MAX: 400,
        })
      );

      markup.find('.zoom-in').click(() => {
        markup.find('.zoom').smoothZoom('zoomIn');
      });

      markup.find('.zoom-out').click(() => {
        markup.find('.zoom').smoothZoom('zoomOut');
      });

      markup.find('.close-zoom').click(() => {
        markup
          .one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
            markup.find('.zoom').smoothZoom('destroy');
            markup.remove();
          })
          .removeClass('in');
      });
    })
    .on('click', '.collection-object-image .collection-obj-full-screen', ({ target }) => {
      const el = $(target).closest('.collection-object-image');

      el.trigger('open-full-screen.collection-object-image');
    });
});

import './style.scss';
import './exhibition-detail-block.tpl.php';
import 'script-loader!bootstrap-sass/assets/javascripts/bootstrap/tooltip';

jQuery($ => {
  jQuery('.exhibition-detail-block-wrapper [data-toggle="tooltip"]').tooltip();

  jQuery(document).on('click', '.accordion .accordion__button', function(j) {
    $('.accordion .accordion__inner').removeClass('selected');
    $(this)
      .closest('.accordion__inner')
      .addClass('selected');

    var dropDown = $(this)
      .closest('.accordion__inner')
      .find('.accordion__content');

    $(this)
      .closest('.accordion')
      .find('.accordion__content')
      .not(dropDown)
      .slideUp();

    if ($(this).hasClass('active')) {
      $(this)
        .closest('.accordion__inner')
        .removeClass('selected');
      $(this).removeClass('active');
    } else {
      $(this)
        .closest('.accordion')
        .find('a.active')
        .removeClass('active');
      $(this).addClass('active');
    }

    dropDown.stop(false, true).slideToggle();

    j.preventDefault();
  });
});

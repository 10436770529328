import './style.scss';
import './paragraph-three-content-highlight.tpl.php';
import Colcade from 'colcade';

jQuery($ => {
  $(initialize);
  window.threeContentHighlights = initialize;
});

function initialize($) {
  $(document).ready(function() {
    const grid = $('.paragraph-three-content-highlight .grid');
    if (grid.length > 0) {
      grid.each(function() {
        new Colcade(this, {
          columns: '.grid-col',
          items: '.grid-item',
        });
      });
    }
  });
}

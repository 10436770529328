import './collection-object-info.tpl.php';
import './style.scss';

jQuery($ => {
  $(document).off('click', '.collection-object-info__accordion > li .collection-object-info__accordion-tab a');
  $(document).on('click', '.collection-object-info__accordion > li .collection-object-info__accordion-tab a', function(
    e
  ) {
    $('.collection-object-info__accordion > li').removeClass('selected');
    $(this)
      .closest('li')
      .addClass('selected');

    var dropDown = $(this)
      .closest('li')
      .find('.collection-object-info__accordion-inside');
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    dropDown
      .stop(false, true)
      .slideToggle()
      .css('display', 'flex');

    e.preventDefault();
    return false;
  });
});
